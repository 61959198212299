/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  title: 'Weezchat, tchat illimité',
  api: 'https://chat-api-new-pp.contactdve.com/api',
  graphQL: 'https://api.pp.weezchat.fr/graphql',
  chat: 'https://pp-chat-liveengine.contactdve.com/',

  waitingAPI: 'dating-notification-pp.dv-content.io/',
  waitingToken: 'TtD4dFtMCfu92eCK',
  id: 'ck5y1z6gwsdll0b625vowrk46',
  gtm: 'GTM-WQ4P2S',
  gtmCountry: 'GTM-WXD2SPHN',
  completeProfileInputs: ['email'],
  dvp4m: true,
  gsv: null,
  appLink: 'https://www.goswipi.com/redirect_stores',
  didomi: 'YezEWhxT',
};
